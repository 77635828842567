import "./App.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useCallback, useState } from "react";
import background from "./bgimages/biobg.jpg";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

const styles = {
  container: {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  },
};

function Bio() {
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate("/");
  };
  return (
    <div className="App" style={styles.container}>
      <Grid className="grid-container" item xs={12}>
        <div className="arrow-left" onClick={handleGoHome}>
          &larr;
        </div>
      </Grid>
      <Grid
        sx={{
          paddingTop: "5vh",
          paddingRight: "30vw",
          paddingLeft: "5vw",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography color={"white"} textAlign={"left"} variant="h5">
          Mustafa Özel
        </Typography>
        <Typography color={"white"} textAlign={"left"} variant="h7">
          He was born in 1961 in Bilecik,Turkey. Graduated from the Marmara
          University in 1984.
        </Typography>
        <Typography color={"white"} textAlign={"left"} variant="h6">
          SOLO
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h9"
        >
          1985 Dörtboyut sanat galerisi,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          1987 Turkuvaz sanat galerisi,Ankara
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          1989 Maltepe ressamları sanat galerisi,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          1992 Sanat yapım,Ankara
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          1994 Valör sanat galerisi,Ankara
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          1995 Galeri Mazhar Zorlu,İzmir
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          1996 Maltepe sanat galerisi,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          1997 Maltepe sanat galerisi,İstanbul ,
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          1998 Evin sanat galerisi,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          1998 Galeri Art Home,Mersin
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          2000 Evin sanat galerisi,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          2000 Valör sanat galerisi,Ankara
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          2003 Galerie Bernard Desroches,Montreal
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          2006 Karşı sanat çalışmaları,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          2009 Casa dellarte galeri,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          2011 Casa dellarte galeri,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          2011 Turkuvaz sanat galerisi,Ankara
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          2013 Beyoğlu Akademililer sanat merkezi,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          2019 Onart gallery,Florence
        </Typography>
        <Typography color={"white"} textAlign={"left"} variant="h6">
          Some group exhibitions
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h8"
        >
          1985 Altı Maltepeli sergisi, Dört boyut art gallery,lstanbul
        </Typography>

        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          1989 50.Devlet resim ve heykel sergisi,Ankara
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          1996 6. lstanbul art fair
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          1997 Galerie TUT, Switzerland
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          1997 Karma sergi, Evin art gallery,lstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          1997 Karma sergi, Reform art gallery,lstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          1998 8. lstanbul art fair
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2005 TUYAP sanat fuarı,lstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2008 Contemporary lstanbul Art Fair
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2009 Contemporary İstanbul Art Fair
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2009 Sotheby"s, Contemporary Art Turkish,London
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2009 Classical, Daire sanat,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2010 Contemporary İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2010 Sotheby"s Contemporary Art Turkish,London
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2011 TUYAP art fair,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2012 Art Bosphorus Art Fair,İstanbul
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2012 Portart gallery,Ankara
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2013 Artis Causa,Selanik,Greece
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2017 TUYAP lstanbul art fair
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2017,2019 BP Portrait Award,Great Britian
        </Typography>
        <Typography
          color={"white"}
          fontSize={13}
          textAlign={"left"}
          variant="h6"
        >
          2023 RSPP Mall Galleries, London
        </Typography>
      </Grid>
    </div>
  );
}

export default Bio;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DefaultLayout.scss";
import { Grid } from "@mui/material";

function DefaultLayout(props) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageList, setImageList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Import the images dynamically
    importImages();
  }, []);

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setCurrentImageIndex(index);
    setShowOverlay(true);
  };

  const handleNextImage = (event) => {
    event.stopPropagation();
    const nextIndex = (currentImageIndex + 1) % props.imageList.length;
    setSelectedImage(imageList[nextIndex]);
    setCurrentImageIndex(nextIndex);
  };

  const handlePrevImage = (event) => {
    event.stopPropagation();
    let nextIndex = (currentImageIndex - 1) % props.imageList.length;
    if (nextIndex < 0) {
      nextIndex = props.imageList.length - 1;
    }
    setSelectedImage(imageList[nextIndex]);
    setCurrentImageIndex(nextIndex);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setSelectedImage(null);
    setCurrentImageIndex(0);
  };

  const importImages = async () => {
    try {
      const importedImages = await Promise.all(
        props.imageList.map((imageName) =>
          import(`../page_images/${props.folder}/${imageName}`).then(
            (module) => module.default
          )
        )
      );
      setImageList(importedImages);
    } catch (error) {
      console.log("Error importing images:", error);
    }
  };

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="default-layout">
      <Grid className="grid-container" container spacing={2}>
        <Grid item xs={12}>
          <div className="arrow-left" onClick={handleGoHome}>
            &larr;
          </div>
          <h2 className="title">{props.pageName}</h2>
        </Grid>
        {imageList.map((image, index) => (
          <Grid item xs={6} sm={3} key={index}>
            <div className="container">
              <img
                className="image"
                src={image}
                alt={`Image ${index + 1}`}
                onClick={() => handleImageClick(image, index)}
              />
            </div>
          </Grid>
        ))}
      </Grid>
      {showOverlay && (
        <div onClick={handleCloseOverlay} className="overlay">
          <div className="overlay-content">
            <img
              onClick={handleNextImage}
              className="overlay-image"
              src={selectedImage}
              alt={`Image ${currentImageIndex + 1}`}
            />
          </div>
          <div className="overlay-arrow-right" onClick={handleNextImage}>
            &rarr;
          </div>
          <div className="overlay-arrow-left" onClick={handlePrevImage}>
            &larr;
          </div>
          <div className="overlay-close" onClick={handleCloseOverlay}>
            &times;
          </div>
        </div>
      )}
    </div>
  );
}
export default DefaultLayout;

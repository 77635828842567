import React from "react";
import { Icon } from "@mui/material";
import { MailOutline, Instagram } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "../styles/Info.scss";

function Info() {
  const navigate = useNavigate();

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/mustafaozelart", "_blank");
  };

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="info">
      <div className="arrow-left" onClick={handleGoHome}>
        &larr;
      </div>
      <div className="div-insta" onClick={handleInstagramClick}>
        <Icon component={Instagram} sx={{ fontSize: 35, marginRight: "8px" }} />
        mustafaozelart
      </div>
      <div className="div-mail">
        <Icon
          component={MailOutline}
          sx={{ fontSize: 35, marginRight: "8px" }}
        />
        mustafa4236ozel@gmail.com
      </div>
    </div>
  );
}

export default Info;

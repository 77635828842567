import React from "react";
import DefaultLayout from "../components/DefaultLayout";

const folderName = "g-o-l";
const imageList = [
  "130x162.JPG",
  "160x192.jpg",
  "162x294cm.JPG",
  "199x240.jpg",
  "IMG_3828.jpg",
  "IMG-1620.jpg",
  "IMG-4106.JPG",
  "IMG-4107.jpg",
];
const pageName = "Games Of Life";
function GamesOfLife() {
  return (
    <DefaultLayout
      pageName={pageName}
      folder={folderName}
      imageList={imageList}
    ></DefaultLayout>
  );
}
export default GamesOfLife;

import React from "react";
import DefaultLayout from "../components/DefaultLayout";

const folderName = "i-o";
const imageList = [
  "7,86x184cm.,8000euro.JPG",
  "30.JPG",
  "42.JPG",
  "102x73.JPG",
  "200x284cm.JPG",
  "f-IMG_3019.JPG",
  "f-L1190769.JPG",
  "havaalanı,142x300cm,2015,tüyb.JPG",
  "IMG_3765.jpg",
  "IMG_3773.JPG",
  "IMG_3788.jpg",
  "L1160542.JPG",
];
const pageName = "Inside Outside";
function InsideOutside() {
  return (
    <DefaultLayout
      pageName={pageName}
      folder={folderName}
      imageList={imageList}
    ></DefaultLayout>
  );
}
export default InsideOutside;

import React from "react";
import DefaultLayout from "../components/DefaultLayout";

const folderName = "b-i-s";
const imageList = [
  "17.JPG",
  "dd.jpg",
  "ddd.jpg",
  "elvra.jpg",
  "IMG_8441.JPG",
  "IMG-2846.JPG",
  "IMG-2861.JPG",
  "IMG-2862.JPG",
  "L1070969.jpg",
  "sdsd.jpg",
  "ss.jpg",
  "_MBK1690.jpg",
  "0.jpg",
];
const pageName = "Body in The Space";
function BodyInTheSpace() {
  return (
    <DefaultLayout
      pageName={pageName}
      folder={folderName}
      imageList={imageList}
    ></DefaultLayout>
  );
}
export default BodyInTheSpace;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Bio from "./Bio";
import GamesOfLife from "./pages/GamesofLife";
import BodyInTheSpace from "./pages/BodyInTheSpace";
import PeopleAndMoment from "./pages/PeopleAndMoment";
import Portraits from "./pages/Portraits";
import InsideOutside from "./pages/InsideOutside";
import Info from "./pages/Info";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/bio" element={<Bio />} />
      <Route path="/games-of-life" element={<GamesOfLife />} />
      <Route path="/body-in-the-space" element={<BodyInTheSpace />} />
      <Route path="/people-and-moment" element={<PeopleAndMoment />} />
      <Route path="/portraits" element={<Portraits />} />
      <Route path="/inside-outside" element={<InsideOutside />} />
      <Route path="/info" element={<Info />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import DefaultLayout from "../components/DefaultLayout";

const folderName = "portraits";
const imageList = [
  "IMG_1587.jpg",
  "IMG_1617.jpg",
  "IMG_2830.jpg",
  "IMG_2831.jpg",
  "IMG_2832.jpg",
  "IMG_2836.jpg",
  "IMG_2838.jpg",
  "IMG_2840.jpg",
  "IMG_2842.jpg",
  "IMG_2843.jpg",
  "IMG_2848.jpg",
  "IMG_2853.jpg",
  "IMG_2854.jpg",
  "IMG_2855.jpg",
  "IMG_2857.jpg",
  "IMG_2858.jpg",
  "IMG_2859.jpg",
  "IMG_2866.jpg",
  "IMG_2868.jpg",
  "IMG_2870.jpg",
  "IMG_2871.jpg",
  "IMG_2874.jpg",
  "IMG_2876.jpg",
  "IMG_2877.jpg",
  "s.jpg",
  "IMG_1467.jpg",
];
const pageName = "Portraits";
function Portraits() {
  return (
    <DefaultLayout
      pageName={pageName}
      folder={folderName}
      imageList={imageList}
    ></DefaultLayout>
  );
}
export default Portraits;

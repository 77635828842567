import React from "react";
import DefaultLayout from "../components/DefaultLayout";

const folderName = "p-a-m";
const imageList = [
  "4f-L1190749.JPG",
  "20220630_151044.jpg",
  "elvira,198x145cm,2012.JPG",
  "IMG_3771.jpg",
  "IMG_3775.jpg",
  "IMG-1618.JPG",
  "IMG-2821.JPG",
  "IMG-2839.JPG",
  "IMG-2844.JPG",
  "IMG-2845.JPG",
  "IMG-2850.JPG",
  "IMG-2860.JPG",
  "130x90.jpg",
  "IMG-2864.JPG",
  "IMG-2867.JPG",
  "IMG-2875.JPG",
  "IMG_3784.jpg",
  "IMG_3819.jpg",
  "IMG_3806.jpg",
  "unnamed.jpg",
  "5.JPG",
];
const pageName = "People And Moment";
function PeopleAndMoment() {
  return (
    <DefaultLayout
      pageName={pageName}
      folder={folderName}
      imageList={imageList}
    ></DefaultLayout>
  );
}
export default PeopleAndMoment;

import "./App.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useCallback, useState } from "react";
import background from "./bgimages/ETLQ1119.jpg";
import background2 from "./bgimages/games-of-life.jpg";
import background3 from "./bgimages/body-in-the-space.jpg";
import background4 from "./bgimages/portraits.jpg";
import background5 from "./bgimages/inside-outside.jpg";
import background6 from "./bgimages/people-and-moment.jpg";
import { useNavigate } from "react-router-dom";

const styles = {
  container: {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  },
  container2: {
    backgroundImage: `url(${background2})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  },
  container3: {
    backgroundImage: `url(${background3})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  },
  container4: {
    backgroundImage: `url(${background4})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  },
  container5: {
    backgroundImage: `url(${background5})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  },
  container6: {
    backgroundImage: `url(${background6})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  },
};

function useHandleClickRoute() {
  const navigate = useNavigate();

  const handleClickRoute = (route) => {
    navigate(route);
  };

  return handleClickRoute;
}

function App() {
  document.title = "Mustafa Ozel";
  const [bg, setBg] = useState(styles.container);

  const handleClickRoute = useHandleClickRoute();

  return (
    <div className="App" style={bg}>
      <Box
        justifyContent="flex-start"
        sx={{
          padding: "15vh 0 15vh 15vh",
        }}
      >
        <div className="div-container">
          <Typography
            textAlign={"left"}
            style={{
              cursor: "pointer",
              width: "fit-content",
              marginBottom: 0,
              paddingBottom: ".3rem",
            }}
            onClick={() => handleClickRoute("/games-of-life")}
            onMouseEnter={() => setBg(styles.container2)}
            onMouseLeave={() => setBg(styles.container)}
            color={"white"}
            variant="h5"
            gutterBottom
          >
            Games Of Life
          </Typography>
        </div>
        <Typography
          textAlign={"left"}
          style={{
            cursor: "pointer",
            width: "fit-content",
            marginBottom: 0,
            paddingBottom: ".3rem",
          }}
          onClick={() => handleClickRoute("/body-in-the-space")}
          onMouseEnter={() => setBg(styles.container3)}
          onMouseLeave={() => setBg(styles.container)}
          color={"white"}
          variant="h5"
          gutterBottom
        >
          Body in The Space
        </Typography>
        <Typography
          textAlign={"left"}
          style={{
            cursor: "pointer",
            width: "fit-content",
            marginBottom: 0,
            paddingBottom: ".3rem",
          }}
          onClick={() => handleClickRoute("/portraits")}
          onMouseEnter={() => setBg(styles.container4)}
          onMouseLeave={() => setBg(styles.container)}
          color={"white"}
          variant="h5"
          gutterBottom
        >
          Portraits
        </Typography>
        <Typography
          textAlign={"left"}
          style={{
            cursor: "pointer",
            width: "fit-content",
            marginBottom: 0,
            paddingBottom: ".3rem",
          }}
          onClick={() => handleClickRoute("/inside-outside")}
          onMouseEnter={() => setBg(styles.container5)}
          onMouseLeave={() => setBg(styles.container)}
          color={"white"}
          variant="h5"
          gutterBottom
        >
          Inside-Outside
        </Typography>
        <Typography
          textAlign={"left"}
          style={{
            cursor: "pointer",
            width: "fit-content",
            marginBottom: 0,
            paddingBottom: ".3rem",
          }}
          onClick={() => handleClickRoute("/people-and-moment")}
          onMouseEnter={() => setBg(styles.container6)}
          onMouseLeave={() => setBg(styles.container)}
          color={"white"}
          variant="h5"
          gutterBottom
        >
          People and Moment
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "15vh",
          paddingTop: "25vh",
          height: "1vh",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            onClick={() => handleClickRoute("/bio")}
            color={"white"}
            textAlign={"left"}
            style={{ cursor: "pointer" }}
          >
            BIOGRAPHY
          </Typography>
          <Typography
            onClick={() => handleClickRoute("/info")}
            color={"white"}
            textAlign={"left"}
            style={{ cursor: "pointer" }}
          >
            INFO
          </Typography>

          <Typography color={"white"} textAlign={"left"}></Typography>
        </Box>
      </Box>
    </div>
  );
}

export default App;
